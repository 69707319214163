body {
  width: 100%;
  background: url('../public/resources/fondo.png') top / cover repeat-y !important; 
  font-family: poppinsnormal;
  color: #FFF; 
}

h1, h2, h3 {
font-family: poppinsbold;
}

/* Inicio - Textos resaltados */
.font-bold {
font-family: poppinsbold;
font-size: 1.05em;
}

.font-bold-blue {
font-family: poppinsbold;
font-size: 1.05em;
color: #2B9D8F;
}
.font-bold-blue1 {
  font-family: poppinsbold;
  font-size: 1.05em;
  color: #2B9D8F;
  display: inline;
  }
/* Fin - Textos resaltados */

/* ESTILOS GENERALES */

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* INICIO - SECCIÓN PRINCIPAL */
.section-main {
  text-align: center;
  min-height: 450px;
}

.section-main-header {
  margin: 10vh 5% 2em 5%;
  justify-content: center;
  align-items: center;
}

.section-main-header h1:nth-child(1) {
  margin-bottom: 0;
  animation: typing 3.5s steps(30,end), transparente .5s infinite step-end alternate;
  animation-fill-mode: forwards;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  margin: auto;
  font-size: 4em;
}

.section-main-header h1:nth-child(2){
  animation: typing2 4s steps(35,end), transparente .5s infinite step-end alternate;
  animation-delay: 3s;
  animation-fill-mode: forwards;
  width: 100%;
  opacity: 0;
  white-space: nowrap;
  overflow: hidden;
  margin: auto;
  font-size: 3.5em;
}


@keyframes typing{
  from{
      width: 0px;

  }
}

@keyframes typing2{
  0%{
      width: 0;
  }
  from{
      opacity: 0;
  }
  1%{
      opacity: 1;
  }
  to{
      opacity: 1;
  }
  100% {
      opacity: 1;
  }
}

@keyframes transparente{
  50%{
      border-color: transparent;
  }
}

.section-main-header p {
  font-size: 1.5em;
  text-align: center;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: poppinsnormal;
  margin-top: 1em;
}


.spline-container {
  background: url('../public/resources/fondo\ para\ animacion3d_2.png');
  background-repeat: no-repeat;
  background-size: 100%;
  max-width: 100%;
  overflow: hidden;
  margin: -5vw 0;
  margin-bottom: 10%;
}
.spline-animation {
  background: transparent;
  width: 100%;
  overflow: hidden;
  margin: -10%, -20%, -10%, -10%;
}

/* Inicio - Boton predeterminado */
.button-classic {
  font-size: 1.1em;
  text-align: center;
  padding: 0.5em 2em;
  margin: 1em;
  border-radius: 5px;
  background-color: #2B9D8F;
  border: 2px solid #2B9D8F;
  color: #FFF;
  font-family: poppinsnormal;
  transition: all 0.2s ease-in-out;
  align-items: center;
  justify-content: center;
}
.button-classic:hover {
  background-color: white;
  color: black;
  border: 2px solid #2B9D8F;
  font-weight: bold;
}
.button-classic:active {
  background-color: #076156;
  box-shadow: 0 0 20px #076156;
}
/* Fin - Boton predeterminado */

.section-main img {
  width: 100%;
}
.section-main-info {
  font-size: 1em;
  margin: 0 10% 2em 10%;
  text-align: center;
  margin-top: -15vh;
  
}

.section-main-info p{
  width: 100%;
  text-align: center;
  font-family: poppinsnormal;
  font-size: 1.2em;
}

.container-figure{
  position: relative;
}

/* FIN - SECCIÓN PRINCIPAL */


/* INICIO - SECCIÓN ELEGIRNOS */
.section-elegirnos {
  text-align: center;
}

.subtitle-header {
  margin-top: 5%;
  margin-bottom: 3%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-elegirnos h2 {
  margin: 10px 0;
  font-weight: bold;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.line-subtitle{
  width: 5vw;
  height: 0.1em;
  background-color: #FFF;
  margin: 0 1vw;
}

/* FIN - SECCIÓN ELEGIRNOS */

/* INICIO - SECCIÓN SERVICIOS */

.subtitle-header h3 {
  font-size: 3em;
  font-weight: bold;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 10px 0;
}

.text-box h2 {
  font-weight: bold;
  padding-top:33px;
  font-family: Poppinsnormal;
  font-size: 1.5em;
  font-weight: bold;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}

.text-box-des{
  font-family: Poppinsnormal;
  font-size: 1em;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}

.section-servicios {
  max-width: 80vw;
  margin: 0 auto;
}

.text-boxes-servicios{
  box-sizing: border-box;
  margin: 2% 5% 5% 5%;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(10, auto);
  gap: 1.5em;
  transition: all 0.2s ease-in;
}

.text-box:hover {
  transform: scale(1);
}

.boton-centrar {
  justify-content: center;
  text-align: center;
}

.text-box{
  background-color: rgba(255,255,255,20%);
  padding: 1em 2em;
  border-radius: 1em;
  text-align: justify;
  grid-row: span 4;
}


.text-box img{
  max-width: 100%;
}

.content-txt-box-4{
  display: flex;
}

.text-box:nth-child(3){
  grid-row: span 5;
}

.text-box:nth-child(4){
  grid-row: span 2;
  grid-column: span 2;
}

.text-box:nth-child(5){
  grid-column: 1/span 1;
}

.text-box:nth-child(7){
  grid-column: 3/span 1;
  grid-row: 6/span 5;
}

/* Inicio - Burbujas */
.container-bubbles{
  position: relative;
}
.bubbles{
  position: absolute;
  z-index: -10;
  width: 35vw;
  max-width: 100%;
}
#bubble-1{
  top: 15vh;
  left: 15vw;
}
#bubble-2{
  right: 20vw;
  bottom: 10vh;
}
/* Fin - Burbujas */
/* FIN - SECCIÓN SERVICIOS */

/* INICIO - SECCIÓN PROYECTOS */
#section-proyectos {
  width: 80%;
  margin: auto;
}
.article-proyectos{
  display: flex;
  margin: 2% 5% 5% 5%;
  gap: 2em;
}
#p-proyectos {
  font-size: 1.2em;
  font-family: poppinsnormal;
  text-align: center;
}
.carousel {
  display: none;
  margin-top: 3vh;
}
.itemCarousel {
  padding: 3vw;
  height: 100%;
}

.text-box-proyectos {
  display: grid;
  width: 268px;
  height: 550px;
  
  flex-grow: 1;
  object-fit: cover;
  transition: .5s ease-in-out;
  border-radius: 15px;
  background-color: rgba(255,255,255,20%);
  padding: 1em 2em;
  justify-content: center;  
  text-align: center;
  grid-row: span 4;
}

.text-box-proyectos:hover {
  width: 80%;
  filter: contrast(120%);
  object-fit: cover;
}

.text-box-proyectos span{
  font-weight: bold;
  height: 120px;
  font-family: poppinsnormal;
  font-size: 35px;
  flex-grow: 0;
  text-align:center;
  padding-top: 70px;
  white-space: normal;
  word-wrap: break-word;
  line-height: 1.2em;
}
.txt-box-img {
  max-width: 95%;
  max-height: 95%;
  object-fit: contain;
  display: block;
  margin: auto;
}

/* FIN - SECCIÓN PROYECTOS */


/*SECCION TESTIMONIOS*/

#section-testimonios {
  background-color: #fff4;
  border-radius: 20px;
  margin: 5%;
  text-align: center;
  margin-bottom: 10vh;
  padding: 10px, 20px, 20px, 20px;
}
.subtitle-header {
  margin-top: 3%;
  margin-bottom: 0 !important;
}
.subtitle-header.header-testimonios h3,
.subtitle-header.header-testimonios .line-subtitle {
  margin-top: 40px;
}
#section-testimonios p {
  font-size: 1.2em;
  font-family: poppinsnormal;
  text-align: center;
  margin: auto;
}
.carousel-testimonios {
  display: block !important;
}
.header-card {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
}
.header-card img {
  height: auto;
  object-fit: contain;
}
.card-carousel {
  width: 100%;
  padding: 20px 35px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #fff;
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.card-carousel p{ 
  margin-bottom: auto;
}
.card-1-carousel {
  background-color: #2B9D8F !important;
  z-index: 1;
}
.card-2-carousel {
  background-color: #E9C46A !important;
  z-index: 2;
}
.card-3-carousel {
  background-color: #DF9D67 !important;
  z-index: 1;
}

/* CONTACTO */

.cuadro {
  width: 70%;
  max-height: 60%;
  border-radius: 15px;
  align-content:center;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(255,255,255,20%);
  margin:auto;
  margin-bottom: 5vh;
  align-content: center;
  padding: 2%;
  padding-top: 0;
  position: relative;
}

.form-contacto {
  padding: 5%
}

.text-form {
  font-size: 1em;
  font-family: poppinsnormal;
  margin-top: 2%;
  margin-left: 1%;
}
.text-form-label {
  font-size: 1.1em;
  font-family: poppinsbold;
  margin-top: 1.5em;
}
.p-form {
  display: none;
  font-size: 1.3em;
  font-family: poppinsbold;
  margin-top: 1.5em;
  text-align: center;
}

.radio-group{
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.button-base {
  width: 40%;
  font-size: 1em;
  text-align: center;
  padding: 0.5em 2em;
  margin-top: 2em;
  border-radius: 5px;
  background-color: #2B9D8F;
  border: 2px solid #2B9D8F;
  color: #FFF;
  font-family: poppinsnormal;
  transition: all 0.2s ease-in-out;
  align-items: center;
}
.button-base:hover {
  background-color: white;
  color: black;
  border: 2px solid #2B9D8F;
  font-weight: bold;
}

.img-form {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%); 
  width: 50%;
  margin-right: 1%;
}

.burbuja1{
  width: 26%;
  top: 10%;
  left: 25%;
  z-index: -1;
  opacity: 0.15;
  position: absolute;
}

.input, .input-des{
  width: 40%;
  padding: 0.8em;
  border-radius: 0.5em;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  font-family: poppinsnormal;
  font-size: 1.05em;
  color: rgba(0, 0, 0, 0.9);
}

.columnas-servicio{
  display: grid;
  grid-template-columns: repeat(2,21%);
  grid-template-rows: 40%;
  font-family: poppinsnormal;
}

@media (max-width: 767px) {
  h2, h1{
    font-size: 1.2em !important;
  }
  .text-box h2{
    font-size: 1.05em !important;
  }
  p, .button-classic {
    font-size: 0.9em !important;
  }
  .section-main-header {
    margin: 5vh 2% 2em 2%;
  }  
  .section-main-header h1{
    margin: 5vh 0 2em 0;
    font-size: 1.4em !important;
  }
  .text-box{
    text-align: center;
  }
  #bubble-1, #bubble-2 {
    display: none;
  }
  .spline-container{
    position: relative;
    margin-bottom: 30% !important;
  }
  .spline-animation {
    width: 130%;
    position: absolute;
    top: 0;
  }
  .section-main-info{
    margin-top: -85vh;
  }
  .text-boxes-servicios {
    display: flex; 
    flex-direction: column; 
    gap: 1em; 
    margin: 0 auto; 
    padding: 0 10%; 
  }
  .text-box {
    width: 100%; 
    max-width: 100%; 
    margin: 0 auto; 
    box-sizing: border-box; 
    background-color: rgba(255,255,255,20%);
  }
  .text-box-img1{
    margin-top: 10%!important;
    margin-left: 20%!important;
    width: 60%!important;
  }
  .text-box-img {
    align-self: center;
    width: 100%;
  }
  .content-txt-box-4{
    display: inline-block;
  }
  .card-carousel { 
    width: 90%;
    margin: auto;
  }
/* Formulario de contacto */
  .cuadro {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 150px;
    position: relative;
  }
  .subtitle-header {
    align-items: center;
    margin-top: 8vh;
    font-size: 0.5em;
  }
  .subtitle-contacto {
    font-size: 4em !important;
  }
  .text-form-label, .p-form {
    font-size: 0.9em;
  }
  .p-form {
    display: block;
    font-size: 1em !important;
    margin-bottom: 0;
    margin-top: 1em;
  }
  .img-form {
    position: absolute;
    top: -120px;
    left: 45%;
    transform: translateX(-50%);
    width: 50%;
  }
  .input, .input-des {
    width: 100%;
    background-color: rgba(255,255,255,0.2);
    border: 2px solid #BBB;
    color: rgba(0, 0, 0, 0.9);
    font-size: 0.8em !important;
  }
  .button-base {
    width: 100%;
  }
  .label-servicio {
    margin: 0;
  }
  .columnas-servicio {
    display: flex;
    flex-direction: column;
    font-size: 0.8em;
    font-family: poppinsnormal;
  }
  .radio-group {
    margin-bottom: 10px;
    width: 100%; 
  }
  .article-proyectos {
    display: none;
  }
  .carousel {
    display: block !important;
  }
}

 @media (768px <= width <= 1150px) {
  .section-main-header{
    margin: 5vh 0 2em 0;
  }
  .cuadro {
    margin-top: 250px;
  }
  .subtitle-header {
    font-size: 1.2em;
  }
  .text-form-label, .p-form {
    font-size: 1.4em;
  }
  .p-form {
    font-size: 1.65em !important;
  }
  .img-form {
    position: absolute;
    top: -220px;
    left: 45%;
    transform: translateX(-50%);
    width: 50%;
  }
  .input, .input-des {
    width: 100%;
    font-size: 1.1em !important;
  }
  .section-servicios {
    max-width: 100vw;
  }
  .columnas-servicio {
    font-size: 1.1em;
  }
  .button-base {
    font-size: 1.3em;
  }
  .article-proyectos {
    display: none;
  }
  .carousel {
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 1500px) {
  .section-main-header h1{
    font-size: 3em !important;
  }
  .subtitle-header h3{
    font-size: 2.5em !important;
  }
  .text-box h2{
    font-size: 1.4em !important;
  }
  p, .button-classic {
    font-size: 1.2em !important;
  }
  .spline-container{
    position: relative;
    
  }
  .spline-animation {
    width: 100%;
    position: absolute;
    top: 0;
  }
  .section-main-info{
    margin-top: -85vh;
  }
  .spline-container{
    position: relative;
    margin-bottom: 35%;
  }
  .proyectoItem {
    width: 100%;
  }
  .imgFondo {
    pointer-events: none;
    user-select: none;
  }
}




/* CUADRO BLOG */

.contenido-blog {
  width: 80%;
  max-height: 60%;
  border-radius: 15px;
  align-content:center;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(255,255,255,20%);
  margin:auto;
  margin-bottom: 5vh;
  align-content: center;
  padding: 5%;
  padding-top: 0;
  position: relative;
}

.sub-header {
  margin-top: 5%;
  margin-bottom: 3%;
  display: flex;
  justify-content: left;
}

.subtitle-blog {
  font-size: 2.5em;
  font-family: poppinsbold;
}

.text-sub {
  font-family: poppinsnormal;
  font-size: 1.1em;
  padding-left: 60%;
}

.input-group {
  max-width: 33%;
}

.form-control {
  font-size: 1.1em;
  font-family: poppinsnormal;
  margin-top: 1.5em;
  width: 90%;
  padding: 0.8em;
  border-radius: 0.5em;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  color: rgba(0, 0, 0, 0.9);
}

.btn {
  width: 30%;
  font-size: 1.1em;
  text-align: center;
  padding: 0.5em 2em;
  margin-top: 1.5em;
  border-radius: 5px;
  background-color: #2B9D8F !important;
  border: 2px solid #2B9D8F !important;
  color: #FFF !important;
  font-family: poppinsnormal;
  transition: all 0.2s ease-in-out !important;
  align-items: center;
}

.btn:hover {
  background-color: white !important;
  color: black !important;
  border: 2px solid #2B9D8F !important;
  font-weight: bold !important;
}

.nav-blog {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 7%;
  border-radius: 15px;
  font-size: 1.1em;
  font-family: latonormal;
}

.section-imgblog {
  padding: 5%;
}

.section-imgblog img {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  display:flex;
  transition: all 0.2s ease;
}


.contenedor-imgblog {
  padding-top: 60px;
  margin-bottom: 40px;  

}


.overlay {
  transition: all 0.2s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
}

.overlay p {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 0;
}

.img-blog {
  position: relative;
  width: 50%;
  margin-right: 2%;
  margin-bottom: 5%;
  display: flex; 
  flex-wrap: wrap; 
}

.img-blog:hover img {
  opacity: 0.2;
}

.img-blog:hover .overlay {
  opacity: 1;

}


.label-style {
  all: inherit; /* Hereda todos los estilos del contenedor <label> */
}