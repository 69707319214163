.central-proyect{
    width: 100%;
    height: 100%;
    display: flex;
}

.text-div{
    width: 30%;
    margin-left: 7%;
    margin-right: 4%;
    
}
.text-delivery{
    white-space: pre-line;
}
.logoMuza2delivery {
    width: 60vw;
    display: flex;
    margin-left: 0;
    
  }
  .encabezadoDelivery {
    text-align: left;
    margin: 2vh 0;
    margin-bottom: 13%;
  }
.img-div {
    width: 60%;
    margin-right: 2%;
    position: relative;
    margin-bottom: 5%;
    display: flex; /* Usa flexbox para alinear las imágenes en fila */
    flex-wrap: wrap; /* Permitir que las imágenes se envuelvan si no caben en una sola línea */
}

.img-delivery1 {
    display: block;
    width: 90%;
    max-width: 100%; /* Ajustar el ancho máximo al 100% del contenedor */
    height: auto; /* Permitir que la altura se ajuste automáticamente */
     /* Espacio entre las imágenes (ajusta según sea necesario) */
}
.img-delivery2 {
    display: block;
    width: 90%;
    max-width: 100%; /* Ajustar el ancho máximo al 100% del contenedor */
    height: auto; /* Permitir que la altura se ajuste automáticamente */
     /* Espacio entre las imágenes (ajusta según sea necesario) */
}
.img-delivery3 {
    display: block;
    width: 90%;
    max-width: 100%; /* Ajustar el ancho máximo al 100% del contenedor */
    height: auto; /* Permitir que la altura se ajuste automáticamente */
     /* Espacio entre las imágenes (ajusta según sea necesario) */
}
.encabezadoproyect{
    text-align: center;
    margin: 2vh 0;
    margin-bottom: 8%;
}

.proyectosDivdelivery {
    display: grid;
    grid-template-columns: repeat(3, 26.5vw);
    margin-bottom: 5%;
    gap: 5vw;
    padding: 0 5vw;
  }

  @media (max-width: 766px) {
    .text-div, .img-div {
        width: 90%;
        margin: 0;
    }
    .encabezadoDelivery {
        text-align: center;
        
      }
      .logoMuza2delivery {
        display: none;
        
      }

    .central-proyect {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .img-div {
        margin-top: 20px;
        margin-left: 8%; /* Añade un margen superior para separar las secciones */
    }

    .displaydelivery{
        display: none;
    }


  }