.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb!important;
}
.proyectosDiv {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 26.5vw);
  grid-template-rows: repeat(4, 25.5vw);
  padding: 0 5vw;
  margin: 0 auto;
}
.encabezado {
  text-align: center;
  margin: 0 3vh 5vh 3vh;
}
.fontBold {
  font-weight: bold;
  font-size: 1.05em;
}
.fontBoldBlue {
  font-weight: bold;
  font-size: 1.05em;
  color: #2B9D8F;
}

.logoMuza2 {
  width: 50vw;
  display: flex;
  margin: auto;
}
.proyectosCarousel {
  display: none; 
  margin-top: 3vh;
  width: 80%;
  margin: auto;
}
.botonContacto{
  display: flex;
  justify-content: center;
}

.itemCarousel {
  padding: 3vw;
}
@media (max-width: 1024px) {
  .encabezado {
    margin-bottom: 0;
  }

  .proyectosDiv {
    display: none;
  }
  .proyectosCarousel {
    display: block;
  }
  .proyectoItem {
    width: 10%;
  }
  .imgFondo {
    pointer-events: none;
    user-select: none;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
