.proyectoItem {
    width: 75%;
    position: relative;
    border-radius: 3%;
    overflow: hidden;
    margin: auto;
}
.imgFondo {
    width: 100%;
    height: 100%;
}
.flechaVerMas {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 10%;
}
.flechaVerMas1 {
    position: absolute;
    bottom: 0;
    right: 4%;
    width: 10%;
}
