.chat-container {
  font-family: poppinsnormal;
    width: 70%;
    margin: 3% auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
  .txt-balloon-left, .txt-balloon-right {
    width: 45%;
    padding: 10px 10px 20px 10px;
    position: relative;
    color: #fff;
    font-size: 1em;
    line-height: 1.4em;
    word-wrap: break-word;
    margin: -25px 0;
  }
  .txt-balloon-left {
    align-self: flex-start;
    margin-right: 38%;
  }
  .txt-balloon-right {
    align-self: flex-end;
    margin-left: 38%;
  }
  .txt-balloon-left::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/public/globotxtizquierda.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: -1; 
  }
  .txt-balloon-right::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url('/public/globotxtderecha.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: -1; 
  }
  @keyframes slideInLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slideInRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  .chat-container p {
    margin: 5px 15px;
  }
  .txt-balloon-left, .txt-balloon-right {
    opacity: 0;
  }
  
  .txt-balloon-left.show {
    animation: slideInLeft 0.5s forwards;
  }
  
  .txt-balloon-right.show {
    animation: slideInRight 0.5s forwards;
  }
  


@media (max-width: 768px) {
  .chat-container {
    width: 90%; /* Puedes ajustar el ancho según sea necesario */
  }
  
  .txt-balloon-left, .txt-balloon-right {
    width: 80%;
    margin: 0 auto;
    margin-right: 0;
    margin-left: 0;
    align-self: center;
  }
  
  .txt-balloon-left {
    align-self: center;
    margin-right: 0;
  }
  
  .txt-balloon-right {
    align-self: center;
    margin-left: 0;
  }
}
