/* INICIO - FUENTE: LATO */
@font-face {
    font-family: latolight;
    src: url(Lato/Lato-Light.ttf);
}
@font-face {
    font-family: latonormal;
    src: url(Lato/Lato-Regular.ttf);
}
@font-face {
    font-family: latobold;
    src: url(Lato/Lato-Bold.ttf);
}
/* FIN - FUENTE: LATO */

/* INICIO - FUENTE: POPPINS */
@font-face {
    font-family: poppinslight;
    src: url(Poppins/Poppins-Light.ttf);
}
@font-face {
    font-family: poppinsnormal;
    src: url(Poppins/Poppins-Regular.ttf);
}
@font-face {
    font-family: poppinsbold;
    src: url(Poppins/Poppins-Bold.ttf);
}
/* FIN - FUENTE: POPPINS */