.nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40px 10%;
    padding: 10px 3%;
    background-color: #FFF4;
    border-radius: 15px;
    font-size: 1.1em;
    font-family: latonormal;
  }

.menuNav {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

.nav img {
    width: 80px;
}

.menuItem {
    display: inline-block;
    padding: 0 10px;
}

.menuLink {
    color: #FFF;
    text-decoration: none;
}

.menuLink:hover {
    text-decoration: underline 2px;
}

.menu-icon {
    display: none;
    align-items: center;
    cursor: pointer;
}

.hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 18px;
    width: 22px;
    margin-left: 10px;
}

.hamburger .line {
    height: 2px;
    background-color: #FFF;
    border-radius: 2px;
}

@media (max-width: 768px) {
    .menuNav {
        display: none;
        flex-direction: column;
        width: 100%;
        text-align: center;
        margin-top: 20px;
        padding: 20px 0;
    }

    .menuNav.open {
        display: flex;
    }

    .menu-icon {
        display: flex;
    }

    .menuLink {
        padding: 5px 0;
        display: block;
    }

    .menuItem {
        display: block;
        margin: 0;
    }
}
