.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 2vh 10vw;
    background-color: #FFF4;
    font-size: 1.1em;
    font-family: latonormal;
  }
.infoMuza {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
#logoMuzafooter {
  width: 100px;
}
.footerListaLink {
    padding: 0 0;
    margin: 2% 2%;
}
.footerItem {
    display: inline-block;
    padding: 0 10px;
}
.footerLink {
    color: #FFF;
    text-decoration: none;
}
.footerRedes {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    list-style: none;
    padding: 0;
}
.redSocialItem{
    margin: 0 7px 0 7px;
}
.footerLink:hover {
    text-decoration: underline 2px
}

@media (max-width: 768px) {
    .footer {
      flex-direction: column;
      align-items: flex-start;
    }
    .footerListaLink {
      order: 1;
      margin-bottom: 1vh;
      display: flex;
      flex-direction: column;
    }
    .footerRedes {
      order: 2;
      margin-bottom: 2vh;
    }
    .infoMuza {
      order: 3;
    }
    .footerListaLink,
    .footerRedes,
    .infoMuza {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .footerItem,
    .redSocialItem {
      margin-bottom: 1vh;
    }
    .footerItem {
      display: block;
      padding: 0;
      text-align: center;
    }
    .footerLink {
      display: block;
      width: 100%;
      padding: 1vh 0;
    }
}